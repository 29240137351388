import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { Sim } from "../../app/models/sim";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import SimHistoryItem from "./SimHistoryItem";
import SimHistorySidebar from "./SimHistorySidebar";
import PostItem from "./SimItem";

interface Props {
    simCard: Sim;
}

export default observer(function SimEnrollment({simCard}: Props) {

    const {simStore} = useStore();
    const {polling, eligibleCheck, selectedSim} = simStore;

    const [enrollmentFailed, setEnrollmentFailed] = useState(false);

    async function enrollClick() {
        if(selectedSim)
        {
            if(await eligibleCheck()) {
                toast.info("SIM Enrolled!");
            }
            else {
                setEnrollmentFailed(true);
            }
        } else {
            toast.error("SIM not defined");
        }
    }

    return (
        <div className="text-center py-10">
            
            { enrollmentFailed ? (
                <div className="text-xl px-2 py-4 border border-1 border-slate-200 bg-slate-50 rounded-lg">
                    <div className="font-bold pb-2 border-b-2 text-red-700 "> 😥 Sorry, Enrollment Failed</div>
                    <div className="py-2">We were unable to reliably monitor your SIM, which is usually due to your carrier not supporting our service.</div>
                    <div className="pt-3 text-sm">
                    We keep working to improve coverage of our service, so if your network becomes supported in the future we'll automatically let you know.
                </div>
            </div>
            ) : (
                <>
                    <h2 className="text-2xl font-bold">SIM/Device Enrollment</h2>
                    <div className="text-lg text-slate-700 py-5 px-10">
                        Please ensure your device is turned on and has mobile signal, then press the button below to begin enrollment.
                    </div>
                    <div className="flex items-center space-x-3 mt-2">
                        <div className="w-full px-32 pb-10">
                            <button
                                className={`btn w-full text-lg border-slate-200 hover:border-slate-300 hover:bg-green-50 text-green-500`}
                                onClick={() => enrollClick()}>
                                <svg className="w-8 h-8 fill-current shrink-0" viewBox="0 0 20 20">
                                    <path d="M15.684,16.959L10.879,8.52c0.886-0.343,1.517-1.193,1.517-2.186c0-1.296-1.076-2.323-2.396-2.323S7.604,5.037,7.604,6.333c0,0.993,0.63,1.843,1.517,2.186l-4.818,8.439c-0.189,0.311,0.038,0.708,0.412,0.708h10.558C15.645,17.667,15.871,17.27,15.684,16.959 M8.562,6.333c0-0.778,0.645-1.382,1.438-1.382s1.438,0.604,1.438,1.382c0,0.779-0.645,1.412-1.438,1.412S8.562,7.113,8.562,6.333 M5.55,16.726L10,8.91l4.435,7.815H5.55z M15.285,9.62c1.26-2.046,1.26-4.525,0-6.572c-0.138-0.223-0.064-0.512,0.162-0.646c0.227-0.134,0.521-0.063,0.658,0.16c1.443,2.346,1.443,5.2,0,7.546c-0.236,0.382-0.641,0.17-0.658,0.159C15.221,10.131,15.147,9.842,15.285,9.62 M13.395,8.008c0.475-1.063,0.475-2.286,0-3.349c-0.106-0.238,0.004-0.515,0.246-0.62c0.242-0.104,0.525,0.004,0.632,0.242c0.583,1.305,0.583,2.801,0,4.106c-0.214,0.479-0.747,0.192-0.632,0.242C13.398,8.523,13.288,8.247,13.395,8.008 M3.895,10.107c-1.444-2.346-1.444-5.2,0-7.546c0.137-0.223,0.431-0.294,0.658-0.16c0.226,0.135,0.299,0.424,0.162,0.646c-1.26,2.047-1.26,4.525,0,6.572c0.137,0.223,0.064,0.512-0.162,0.646C4.535,10.277,4.131,10.489,3.895,10.107 M5.728,8.387c-0.583-1.305-0.583-2.801,0-4.106c0.106-0.238,0.39-0.346,0.631-0.242c0.242,0.105,0.353,0.382,0.247,0.62c-0.475,1.063-0.475,2.286,0,3.349c0.106,0.238-0.004,0.515-0.247,0.62c-0.062,0.027-0.128,0.04-0.192,0.04C5.982,8.668,5.807,8.563,5.728,8.387"></path>
                                </svg>
                                <span className="ml-2">{polling ? ("Enrollment Running...") : ("Begin SIM Enrollment")}</span>
                            </button>
                        </div>
                    </div>
                    { polling && (
                        <div className="text-lg text-green-700 m-20 p-4 border border-1 border-slate-200 bg-slate-50 rounded-lg">
                            🚀 Enrollment is now checking if your device and network carrier is supported, this usually takes about 30 seconds to complete...
                        </div>
                    )}
                </>
            )}
        </div>
    )
})