import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import { observer } from "mobx-react-lite";
import { Product } from '../../app/models/product';

declare global {
  interface Window {
    Paddle: any;
  }
}

interface Props {
  product: Product;
}

export default observer(function ProductSelectorItemQuantity({product}: Props){

  const {productStore} = useStore();
  const {quantity, setQuantity} = productStore;

  if(!product) return null;

  return (
      <div>
        {product 
          && product.supportsQuantity 
          && product.minQuantity >= 1
          && product.maxQuantity > 1 && (
          <div className="shrink-0 space-x-2 flex">
            <div className="bg-sky-50 text-xs font-semibold text-center h-6 w-6 border border-slate-400 rounded-sm flex flex-col justify-center items-center outline outline-2 outline-slate-100 hover:bg-sky-200"
              onClick={() => setQuantity(quantity + 1)}>
              <svg className="inline-flex fill-sky-500 mt-1.5 mb-1.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M24 22h-24l12-20z"/>
              </svg>
            </div>
            <div className="bg-sky-50 text-xs font-semibold text-center h-6 w-6 border border-slate-400 rounded-sm flex flex-col justify-center items-center outline outline-2 outline-slate-100"
              onClick={() => setQuantity(quantity - 1)}>
              <svg className="inline-flex fill-slate-500 mt-1.5 mb-1.5" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M12 21l-12-18h24z"/>
              </svg>
            </div>
            <div className="pl-2">
              Quantity: <span className="text-sky-800 font-bold"> {quantity}</span>
            </div>
          </div>
        )}
      </div>
  );
})