import React, { useState, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useStore } from '../../app/stores/store';

import UserAvatar from '../images/user-avatar-32.png';

interface Props {
    align: string;
}

function DropdownProfile({align}: Props) {

  const [dropdownOpen, setDropdownOpen] = useState(false);

  const {userStore} = useStore();
  const {user} = userStore;

  const trigger = useRef(null);
  const dropdown = useRef(null);

  // close on click outside
  useEffect(() => {
    const clickHandler = ( target: any ) => {
      if (!dropdown.current) return;
      // if (!dropdownOpen || dropdown.current.contains(target) || trigger.current.contains(target)) return;
      if (!dropdownOpen) return;
      setDropdownOpen(false);
    };
    document.addEventListener('click', clickHandler);
    return () => document.removeEventListener('click', clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ( keyCode: any ) => {
      if (!dropdownOpen || keyCode !== 27) return;
      setDropdownOpen(false);
    };
    document.addEventListener('keydown', keyHandler);
    return () => document.removeEventListener('keydown', keyHandler);
  });

  return (
    <div className="relative inline-flex">
      <button
        ref={trigger}
        className="inline-flex justify-center items-center group"
        aria-haspopup="true"
        onClick={() => setDropdownOpen(!dropdownOpen)}
        aria-expanded={dropdownOpen}
      >
        <img className="w-8 h-8 rounded-full" src="https://images.unsplash.com/photo-1546776310-eef45dd6d63c?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1020&q=80" width="32" height="32" alt="User" />
        <div className="flex items-center truncate">
          <span className="truncate ml-2 text-sm font-medium group-hover:text-slate-800">{user?.displayName}</span>
          <svg className="w-3 h-3 shrink-0 ml-1 fill-current text-slate-400" viewBox="0 0 12 12">
            <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
          </svg>
        </div>
      </button>

        {dropdownOpen && (
            <div className={`origin-top-right z-10 absolute top-full min-w-44 bg-white border border-slate-200 py-1.5 rounded shadow-lg overflow-hidden mt-1 ${align === 'right' ? 'right-0' : 'left-0'}`}>
                <div
                    ref={dropdown}
                    onFocus={() => setDropdownOpen(true)}
                    onBlur={() => setDropdownOpen(false)}
                >
                    <ul>
                      <li>
                          <Link
                          className="font-medium text-sm text-indigo-500 hover:text-indigo-300 flex items-center py-2 px-3 border-b border-b-slate-300"
                          to="/profile"
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                          >
                            Manage Profile
                          </Link>
                      </li>
                      <li>
                          <Link
                          className="font-medium text-sm text-indigo-500 hover:text-indigo-300 flex items-center pt-5 pb-1 px-3"
                          to="/logout"
                          onClick={() => setDropdownOpen(!dropdownOpen)}
                          >
                          Log Out
                          </Link>
                      </li>
                    </ul>
                </div>
            </div>
        )}

    </div>
  )
}

export default DropdownProfile;