import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import ProductSelector from "../products/ProductSelector";
import SimCreatePanel from "./SimCreatePanel";
import SimCreateProgressBar from "./SimCreateProgressBar";
import SimItem from "./SimItem";
import SimTable from "./SimTable";

export default observer(function SimCreateStepOne() {

    const {simStore, productStore} = useStore();
    const {currentProduct, loadCurrent} = productStore;
    const {simRegistory, loadSims} = simStore;

    useEffect(() => {
        if(currentProduct === undefined) loadCurrent();
    }, [currentProduct, productStore, loadCurrent]);

    return (
    <>
      <h1 className="text-3xl text-slate-800 font-bold mb-6">✨ Pick a Product </h1>
      {/* Form */}

      {productStore.loading && (
        <div>
          Updating please wait...
        </div>
      )}

      {currentProduct !== undefined ? (
        <form>
        <div className="space-y-3 mb-8">
          <label className="relative block cursor-pointer">
            <input type="radio" name="radio-buttons" className="peer sr-only" defaultChecked />
            <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
              <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                <path className="text-indigo-300" d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z" />
                <path className="text-indigo-200" d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z" />
              </svg>
              <span>{currentProduct.name}<br />{productStore.currentConsumed} of {productStore.currentConsumed} licenses consumed</span>
            </div>
            <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
          </label>
        </div>
      </form>
      ) : (
        <form>
          <div className="space-y-3 mb-8">
            <label className="relative block cursor-pointer">
              <input type="radio" name="radio-buttons" className="peer sr-only" defaultChecked />
              <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
                <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                  <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                  <path className="text-indigo-300" d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z" />
                  <path className="text-indigo-200" d="M13 12.588v11l8.486-4.714A1 1 0 0 0 22 18V7.589l-9 4.999Z" />
                </svg>
                <span>I have a company</span>
              </div>
              <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
            </label>
            <label className="relative block cursor-pointer">
              <input type="radio" name="radio-buttons" className="peer sr-only" />
              <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
                <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                  <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                  <path className="text-indigo-300" d="m11 12.588-9-5V18a1 1 0 0 0 .514.874L11 23.588v-11Z" />
                </svg>
                <span>I’m a freelance / contractor</span>
              </div>
              <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
            </label>
            <label className="relative block cursor-pointer">
              <input type="radio" name="radio-buttons" className="peer sr-only" />
              <div className="flex items-center bg-white text-sm font-medium text-slate-800 p-4 rounded border border-slate-200 hover:border-slate-300 shadow-sm duration-150 ease-in-out">
                <svg className="w-6 h-6 shrink-0 fill-current mr-4" viewBox="0 0 24 24">
                  <path className="text-indigo-500" d="m12 10.856 9-5-8.514-4.73a1 1 0 0 0-.972 0L3 5.856l9 5Z" />
                </svg>
                <span>I’m just getting started</span>
              </div>
              <div className="absolute inset-0 border-2 border-transparent peer-checked:border-indigo-400 rounded pointer-events-none" aria-hidden="true"></div>
            </label>
          </div>
        </form>
      )}


    </>
    )
})