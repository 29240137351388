import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import { observer } from "mobx-react-lite";
import ProductSelectorItem from './ProductSelectorItem';

export default observer(function ProductSelectorBilling(){

  const {productStore} = useStore();
  const {loading, yearlySelected, setYearlySelected} = productStore;

  if(loading) return null;

  return (
      <div className="flex justify-center max-w-xs m-auto mb-3" data-aos="zoom-y-out" data-aos-delay="300">
        <div className="relative flex w-full p-1 bg-gray-200 rounded">
          <span
            className="absolute inset-0 m-1 pointer-events-none"
            aria-hidden="true"
          >
            <span className={`absolute inset-0 w-1/2 bg-white rounded shadow transform transition duration-150 ease-in-out ${yearlySelected ? 'translate-x-0' : 'translate-x-full'}`}></span>
          </span>
          <button
            className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${!yearlySelected && 'text-gray-500'}`}
            onClick={(e) => { setYearlySelected(true); }}
          >Bill Yearly <span className="text-green-500">-20%</span>
          </button>
          <button
            className={`relative flex-1 text-sm font-medium p-1 transition duration-150 ease-in-out ${yearlySelected && 'text-gray-500'}`}
            onClick={(e) => { setYearlySelected(false); }}
          >Bill Monthly</button>
        </div>
      </div>
  );
})