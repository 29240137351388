import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import { observer } from "mobx-react-lite";
import { Product } from '../../app/models/product';
import ProductSelectorItemQuantity from './ProductSelectorItemQuantity';

declare global {
  interface Window {
    Paddle: any;
  }
}

interface Props {
  product: Product;
}

export default observer(function ProductSelectorItem({product}: Props){

  const {productStore} = useStore();
  const {yearlySelected, selectedProduct, setProductVariant, currentVariant, currentConsumed, currentQuantity} = productStore;

  const Paddle = window.Paddle;

  const frequency = yearlySelected ? "Yearly" : "Monthly";
  const variant = product.variants.find(s => s.billingFrequency === frequency);

  if(!variant) return null;

  return (
      <li key={product.id}>
        <button
          className={`w-full h-full text-left py-3 px-4 rounded bg-white border ${selectedProduct && product.id === selectedProduct.id ? "shadow-md border-sky-400" : "shadow-sm"} hover:border-slate-300  duration-150 ease-in-out`}
          onClick={() => setProductVariant(product.id, variant.id)} >
          <div className="flex flex-wrap items-center justify-between mb-0.5">
            <span className="font-semibold text-slate-800">{product.name}</span>
            <span className={`font-medium ${selectedProduct && product.id === selectedProduct.id ? "text-emerald-500 text-lg" : "text-emerald-400"} `}>{variant.currencySymbol}{variant.monthlyPrice.toFixed(2)}</span>
          </div>
          {variant.id === currentVariant?.id && (
            <div className='text-slate-700'>
              <span className='font-bold'>Current Licenses:</span> {currentConsumed} of {currentQuantity} currently used
            </div>
          )}
          {selectedProduct && product.id === selectedProduct.id && (
            <ProductSelectorItemQuantity product={product} />
          )}
        </button>
      </li>
  );
})