import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import ContactTableItem from './ContactTableItem';

export default function ContactTable() {

    const {contactStore} = useStore();
    const {contacts} = contactStore;

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-8">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800">All Contacts <span className="text-slate-400 font-medium pl-2">{contacts.length}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">eMail</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Mobile</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200">
              {
                contacts.map(contact => {
                  return (
                    <ContactTableItem contact={contact} key={contact.id} />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}