import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";

export default observer(function ContactDetails() {

    const {contactStore} = useStore();
    const {loadContact, selectedContact, loading} = contactStore;
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const navigate = useNavigate();

    const {id} = useParams<{id: string}>();

    useEffect(() => {
        if(id) loadContact(id);
    }, [id, loadContact]);

    const handleDeleteClick = async () => {
        if(selectedContact) {
            await contactStore.deleteContact(selectedContact.id);
            navigate('/contacts');
        }
    }

    if(loading || !selectedContact) return <LoadingComponent />

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="lg:relative lg:flex">

                        {/* Content */}
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">

                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">

                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-2xl text-slate-600 font-bold">Contact Management</h1>
                                </div>

                                {/* Add card button */}
                                <button
                                    className={`btn bg-rose-400 text-white hover:text-black hover:bg-red-300`}
                                    onClick={() => handleDeleteClick()}
                                >Delete Contact</button>

                            </div>

                            {/* Contacts */}
                            <div className="space-y-2">
                                
                                <div className="text-md text-slate-700">{selectedContact.name}</div>
                                <div className="text-md text-slate-700">{selectedContact.emailAddress}</div>
                                <div className="text-md text-slate-700">{selectedContact.mobileNumber}</div>
                            
                            </div>

                        </div>

                    </div>
                </main>

            </div>
        </div>
    )
})