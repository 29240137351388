import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import SimCreatePanel from "./SimCreatePanel";
import SimItem from "./SimItem";
import SimTable from "./SimTable";

export default observer(function SimList() {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {simStore} = useStore();
    const {simRegistory, loadSims} = simStore;
    const [simCreatePanelOpen, setSimCreatePanelOpen] = useState(false);

    useEffect(() => {
        if(simRegistory.size < 1) simStore.loadSims();
    }, [simRegistory.size, simStore, loadSims]);


    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-slate-50">
                
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">SIM's & Devices</h1>
                            </div>

                            {/* Right: Actions */}
                            <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                                {/* Add sim button */}
                                <button
                                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white"
                                    onClick={() => setSimCreatePanelOpen(true)}
                                    >
                                    <svg className="w-4 h-4 fill-current opacity-50 shrink-0" viewBox="0 0 16 16">
                                    <path d="M15 7H9V1c0-.6-.4-1-1-1S7 .4 7 1v6H1c-.6 0-1 .4-1 1s.4 1 1 1h6v6c0 .6.4 1 1 1s1-.4 1-1V9h6c.6 0 1-.4 1-1s-.4-1-1-1z" />
                                    </svg>
                                    <span className="hidden xs:block ml-2">Add SIM / Device</span>
                                </button>
                            </div>

                        </div>

                        <SimCreatePanel panelOpen={simCreatePanelOpen} setPanelOpen={setSimCreatePanelOpen} />

                    </div>
                </main>

                {/* Table */}
                <SimTable />

            </div>

        </div>
    )
})