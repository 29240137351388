import React from "react";
import { ErrorMessage, Form, Formik } from "formik";
import { observer } from "mobx-react-lite";
import { useStore } from "../../app/stores/store";
import * as Yup from 'yup';
import MyTextInput from "../../app/common/form/MyTextInput";
import ValidationErrors from "../errors/ValidationErrors";

interface Props {
    panelOpen: boolean;
    setPanelOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export default observer(function ContactCreatePanel({panelOpen, setPanelOpen}: Props) {

    const {contactStore} = useStore();

    if(!panelOpen) return null;

    return (
        <div
            className={`absolute inset-0 sm:left-auto z-20 transform shadow-xl transition-transform duration-200 ease-in-out ${
            panelOpen ? 'translate-x-' : 'translate-x-full'
            }`}>
            <div className="sticky top-16 bg-slate-50 overflow-x-hidden overflow-y-auto no-scrollbar shrink-0 border-l border-slate-200 w-full sm:w-[390px] h-[calc(100vh-64px)]">
                <button
                onClick={() => setPanelOpen(false)}
                className="absolute top-0 right-0 mt-6 mr-6 group p-2"
                >
                <svg className="w-4 h-4 fill-slate-400 group-hover:fill-slate-600 pointer-events-none" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
                    <path d="m7.95 6.536 4.242-4.243a1 1 0 1 1 1.415 1.414L9.364 7.95l4.243 4.242a1 1 0 1 1-1.415 1.415L7.95 9.364l-4.243 4.243a1 1 0 0 1-1.414-1.415L6.536 7.95 2.293 3.707a1 1 0 0 1 1.414-1.414L7.95 6.536Z" />
                </svg>
                </button>
                <div className="py-8 px-4 lg:px-8">
                <div className="max-w-sm mx-auto lg:max-w-none">
                    <div className="text-slate-800 font-semibold text-center mb-1">Create Contact</div>
                    {/* Details */}
                        <Formik
                            initialValues={{name: '', emailAddress: '', mobileNumber: '', error: null}}
                            onSubmit={(values, {setErrors}) => {
                                    contactStore.create(values).catch(error => setErrors({error}));
                                    setPanelOpen(false);
                                }}
                            validationSchema={Yup.object({
                                name: Yup.string().required(),
                                emailAddress: Yup.string().email().optional(),
                                mobileNumber: Yup.string().optional()
                            })}
                        >
                            {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                                <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                                    <div>
                                        <div className="space-y-4">
                                            <MyTextInput name='name' label='Friendly Contact Name' placeholder="My eMail" />
                                            <MyTextInput name='emailAddress' label='eMail Address' placeholder='tom@test.com' />
                                            <MyTextInput name='mobileNumber' label='Mobile/Cell (SMS) Number' placeholder='447511123123' />
                                            <ErrorMessage 
                                                name='error' render={() => 
                                                    <ValidationErrors errors={errors.error} /> }
                                            />
                                        </div>
                                        <div className="flex items-center justify-between mt-6">
                                            <button
                                                disabled={!isValid || !dirty || isSubmitting} 
                                                className="btn w-full border-slate-200 hover:border-slate-300 hover:bg-green-100 bg-green-50 text-slate-600"
                                                type='submit'
                                                >Create</button>
                                        </div>
                                    </div>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </div>
            </div>
        </div>
    )
})