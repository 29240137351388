import { observer } from "mobx-react-lite";
import React from "react";

interface Props {
    progress: number;
    lastStep: number;
    setProgress: React.Dispatch<React.SetStateAction<number>>;
}

export default observer(function ProgressBar({progress, setProgress, lastStep}: Props){

    const steps: number[] = [1,2,3,4];

    return (
        <div className="px-4 pt-12 pb-8">
            <div className="max-w-md mx-auto w-full">
                <div className="relative">
                <div className="absolute left-0 top-1/2 -mt-px w-full h-0.5 bg-slate-200" aria-hidden="true"></div>
                <ul className="relative flex justify-between w-full">
                    {steps.map((nextStep) => (
                        <li key={nextStep}>
                            <div
                                className={`flex items-center justify-center w-6 h-6 rounded-full text-xs font-semibold ${nextStep === progress ? ("bg-indigo-500 text-white") : ("bg-slate-100 text-slate-500")} `}
                                    >{nextStep}</div>
                        </li>
                    ))}
                </ul>
                </div>
            </div>
        </div>
    )
})