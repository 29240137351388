import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SimCardLog } from '../../app/models/simcardlog';
import { useStore } from '../../app/stores/store';

interface Props {
    log: SimCardLog;
}

export default function LogTableItem({log} : Props) {
  
  const {logStore} = useStore();
  const {read} = logStore;
  const navigate = useNavigate();

  const handleClick = async () => {
    if(log.readAt === null) await read(log.id);
    navigate(`/logs/${log.id}`);
  }

  return (
    <tr className={`hover:bg-yellow-50 ${log.readAt === null ? 'bg-green-100' : 'bg-slate-200'} cursor-pointer`}
      onClick={() => handleClick()}>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="font-medium text-slate-800">{log.createdAt}</div>
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{log.name}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{log.description}</div>
      </td>

    </tr>
  );
}