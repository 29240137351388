import { createContext, useContext } from "react";
import CommonStore from "./commonStore";
import ContactStore from "./contactStore";
import LogStore from "./logStore";
import ModalStore from "./modalStore";
import ProductStore from "./productStore";
import SimStore from "./simStore";
import UserStore from "./userStore";

interface Store {
    commonStore: CommonStore;
    simStore: SimStore;
    userStore: UserStore;
    modalStore: ModalStore;
    productStore: ProductStore;
    contactStore: ContactStore;
    logStore: LogStore;
}

export const store: Store = {
    commonStore: new CommonStore(),
    userStore: new UserStore(),
    modalStore: new ModalStore(),
    simStore: new SimStore(),
    productStore: new ProductStore(),
    contactStore: new ContactStore(),
    logStore: new LogStore()
}

export const StoreContext = createContext(store);

export function useStore() {
    return useContext(StoreContext);
}
