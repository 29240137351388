import { ErrorMessage, Form, Formik } from 'formik';
import React, { useState, useEffect } from 'react';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';
import ValidationErrors from '../errors/ValidationErrors';
import * as Yup from 'yup';

export default function NewPasswordForm() {

    const {userStore} = useStore();
    const {user} = userStore;

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-8">
      <div>

        {/* Table */}
        <div className="max-w-sm mx-auto px-4 py-8">
            <h1 className="text-xl text-slate-800 font-bold mb-6">✨ Update Password!</h1>
            <Formik
                initialValues={{currentPassword: '', newPassword: '', error: null}}
                onSubmit={(values, {setErrors}) => userStore.updatePassword(values).catch(error => 
                    setErrors({error}))
                  }
                    validationSchema={Yup.object({
                      currentPassword: Yup.string().required(),
                      newPassword: Yup.string().required(),
                    })}
            >
                {({handleSubmit, isSubmitting, errors, isValid, dirty}) => (
                    <Form className='ui form error' onSubmit={handleSubmit} autoComplete='off'>
                        <div>
                            <div className="space-y-4">
                                <MyTextInput name='currentPassword' label='Current Password' placeholder='Current Password' type='password' />
                                <hr />
                                <MyTextInput name='newPassword' label='New Password' placeholder='New Password' type='password' />
                                <ErrorMessage 
                                    name='error' render={() => 
                                        <ValidationErrors errors={errors.error} /> }
                                />
                            </div>
                            <div className="flex items-center justify-between mt-6">
                                <button
                                    disabled={!isValid || !dirty || isSubmitting} 
                                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                                    type='submit'
                                    >Save New Password</button>
                            </div>
                        </div>
                    </Form>
                )}
            </Formik>
        </div>

      </div>
    </div>
  );
}