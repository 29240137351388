import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import NewPasswordForm from "./NewPasswordForm";

export default observer(function EmailConfirm() {

    const {userStore} = useStore();
    const {verifyEmail, loading, verifySuccess, logout} = userStore;
    
    const {email} = useParams<{email: string}>();
    const {code} = useParams<{code: string}>();

    useEffect(() => {
        if(email && code) verifyEmail(email, code);
    }, [])

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-slate-50">
                
                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">📧 Confirming eMail <span className="text-slate-400"> - {email}</span></h1>
                            </div>

                        </div>

                    </div>
                </main>

                {/* Details */}
                <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-2 md:mx-32">
                    <div>
                        <div className="mx-auto px-4 py-8">
                            <div className="text-xl text-slate-800 font-bold mb-6 text-center">
                                {loading ? (
                                    <span className="text-sky-700">⌛ Please wait, this should only take a few seconds...</span>
                                ) : (
                                    <>
                                        {verifySuccess ? (
                                            <span className="text-emerald-700">😀 Your eMail is verified, please <Link to='/login' className="underline text-emerald-800">login here</Link></span>
                                        ) : (
                                            <span className="text-red-700">😥 Sorry, it looks like the verification failed, please contact support</span>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
})