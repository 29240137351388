import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import LogTableItem from './LogTableItem';
import ContactTableItem from './LogTableItem';

export default observer (function LogTable() {

    const {logStore} = useStore();
    const {logs, markAllRead, loading, unreadCount} = logStore;

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-8">
      <header className="px-5 py-4 flex">
        <h2 className="w-full font-semibold text-slate-800">All Logs <span className="text-slate-400 font-medium pl-2">{logs.length}</span></h2>
        {unreadCount > 0 && (
          <button
            className='flex-none btn-xs text-white bg-indigo-400 hover:bg-indigo-700 shadow rounded-md float-right'
            onClick={() => markAllRead()}>{loading ? "Loading..." : "Mark All Read"}</button>
        )}
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Created</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Description</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200">
              {
                logs.map(log => {
                  return (
                    <LogTableItem log={log} key={log.id} />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
})