import axios, { AxiosError, AxiosResponse } from "axios";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { PaginatedResult } from "../models/pagination";
import { Sim, SimCreateDto, SimUpdateDto } from "../models/sim";
import { ConfirmEmailResult, ConfirmEmailValues, User, UserFormValues, UserPasswordFormValues } from "../models/user";
import { store } from "../stores/store";
import { SimHistory } from "../models/simHistory";
import { CurrentProduct, Product } from "../models/product";
import { EnrollDto } from "../models/enroll";
import { Contact, ContactCreateDTO } from "../models/contact";
import { SimCardLog } from "../models/simcardlog";

const sleep = (delay: number) => {
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

axios.interceptors.request.use(config => {
    const token = store.commonStore.token;
    if(token) config.headers!.Authorization = `Bearer ${token}`
    return config;
})

axios.interceptors.response.use(async response => {
    if(process.env.NODE_ENV === 'development') {
        await sleep(1000);
    }
    
    const pagination = response.headers['pagination'];
    if(pagination) {
        response.data = new PaginatedResult(response.data, JSON.parse(pagination));
        return response as AxiosResponse<PaginatedResult<any>>
    }

    return response;

}, (error: AxiosError) => {
    
    const{data, status, config} = error.response!;
 
    switch(status) {
        case 400:
            if(typeof data === 'string') {
                toast.error(data);
            }
            if(data.errors){
                const modalStateErrors = [];
                for (const key in data.errors){
                    if (data.errors[key]) {
                        modalStateErrors.push(data.errors[key]);
                    }
                }
                throw modalStateErrors.flat();
            }
            break;
        case 401:
            toast.error('Unauthorised');
            break;
        case 404:
            // There are times when we don't really care
            // toast.error('Not Found');
            break;
        case 500:
            store.commonStore.setServerError(data);
            toast.error('Server Error');
            break;
    }
    return Promise.reject(error);
})

const responseBody = <T> (response: AxiosResponse<T>) => response.data;

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responseBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responseBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responseBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responseBody)
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user),
    updatePassword: (password: UserPasswordFormValues) => requests.put<User>('/account/updatePassword', password),
    confirmEmail: (values: ConfirmEmailValues) => requests.post<ConfirmEmailResult>('/account/confirmEmail', values),
}

const Sims = {
    list: () => requests.get<Sim[]>('/sim'),
    details: (id: string) => requests.get<Sim>(`/sim/${id}`),
    poll: (id: string) => requests.get<SimHistory>(`/sim/${id}/poll`),
    disable: (id: string) => requests.post(`/sim/${id}/disable`, {}),
    enable: (id: string) => requests.post(`/sim/${id}/enable`, {}),
    create: (sim: SimCreateDto) => requests.post<Sim>('/sim/create', sim),
    update: (id: string, sim: SimUpdateDto) => requests.put<Sim>(`/sim/${id}`, sim),
    enroll: (id: string) => requests.get<EnrollDto>(`/sim/${id}/enroll`),
    delete: (id: string) => requests.del(`/sim/${id}`)
}

const Products = {
    list: () => requests.get<Product[]>('/product'),
    current: () => requests.get<CurrentProduct>('/product/current'),
    details: (id: string) => requests.get<Product>(`/product/${id}`)
}

const Contacts = {
    list: () => requests.get<Contact[]>('/contact'),
    details: (id: string) => requests.get<Contact>(`/contact/${id}`),
    delete: (id: string) => requests.del(`/contact/${id}`),
    create: (contact: ContactCreateDTO) => requests.post<Contact>('/contact/create', contact)
}

const Logs = {
    list: () => requests.get<SimCardLog[]>('/logs'),
    details: (id: string) => requests.get<SimCardLog>(`/logs/${id}`),
    delete: (id: string) => requests.del(`/logs/${id}`),
    read: (id: string) => requests.post(`/logs/${id}/read`, {}),
    markAllRead: () => requests.post(`/logs/allRead`, {}),
    unread: (id: string) => requests.post(`/logs/${id}/unread`, {}),
}

const agent = {
    Account,
    Sims,
    Products,
    Contacts,
    Logs
}

export default agent;