import { makeAutoObservable, runInAction } from "mobx";
import { off } from "process";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Contact, ContactCreateDTO } from "../models/contact";
import { Product, ProductVariant } from "../models/product";


export default class ContactStore {

    contactRegistory = new Map<string, Contact>();
    selectedContact: Contact | undefined = undefined;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get contacts() {
        return Array.from(this.contactRegistory.values());
    }

    public loadContacts = async () => {
        this.setLoading(true);
        try {
            const result = await agent.Contacts.list();
            result.forEach(contact => {
                this.setContact(contact);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public loadContact = async (id: string) => {
        this.selectedContact = undefined;
        let contact = this.contactRegistory.get(id);
        if(contact) {
            this.selectedContact = contact;
            return contact;
        }
        else{
            this.setLoading(true);
            try {
                contact = await agent.Contacts.details(id);
                this.setContact(contact);
                runInAction(() => {
                    if(contact)
                    {
                        this.selectedContact = contact;
                    }
                })
                this.setLoading(false);
                return contact;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    public create = async (contact: ContactCreateDTO) => {
        this.setLoading(true);
        try {
            const newContact = await agent.Contacts.create(contact);
            runInAction(() => {
                if(newContact) {
                    this.setContact(newContact);
                    this.loadContact(newContact.id);
                }
                this.setLoading(false);
            })
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public deleteContact = async (id: string) => {
        try {
            await agent.Contacts.delete(id);
            runInAction(() => {
                this.contactRegistory.delete(id);
                if(this.selectedContact?.id === id) this.selectedContact = undefined;
            })
        } catch (error) {
            console.log(error);
        }
    }
    
    private setContact = (contact: Contact) => {
        this.contactRegistory.set(contact.id, contact);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

}
