import { Formik, ErrorMessage, Form } from 'formik';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link } from 'react-router-dom';
import MyTextInput from '../../app/common/form/MyTextInput';
import { useStore } from '../../app/stores/store';

export default observer( function LoginForm(){

    const {userStore} = useStore();
  
    return (

        <div className="max-w-sm mx-auto px-4 py-8">
            <h1 className="text-3xl text-slate-800 font-bold mb-6">✨ Welcome back!</h1>

            <Formik
                initialValues={{email: '', password: '', error: null}}
                onSubmit={(values, {setErrors}) => userStore.login(values)
                .catch(error => setErrors({error: 'Invalid eMail or Password'}))}>

                {({handleSubmit, isSubmitting, errors}) => (
                    <Form onSubmit={handleSubmit}>
                        <div className="space-y-4">
                            <MyTextInput name='email' placeholder='Email' />
                            <MyTextInput name='password' placeholder='Password' type='password' />
                            <ErrorMessage 
                                name='error' render={() => (
                                    <p>{errors.error}</p>
                                )}
                            />
                            <div className="flex items-center justify-between mt-6">
                                <div className="mr-1">
                                    <Link className="text-sm underline hover:no-underline" to="/reset-password">Forgot Password?</Link>
                                </div>
                                <button
                                    disabled={isSubmitting} 
                                    className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-3"
                                    type="submit"
                                    >Login</button>
                            </div>
                        </div>
                    </Form>
                )}

            </Formik>

            {/* Footer */}
            <div className="pt-5 mt-6 border-t border-slate-200">
                <div className="text-sm">
                    Don’t you have an account? <Link className="font-medium text-indigo-500 hover:text-indigo-600" to="/register">Sign Up</Link>
                </div>
            </div>
        </div>
        
    )
})