import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import ProductSelector from "../products/ProductSelector";
import SimCreateNav from "./SimCreateNav";
import SimCreatePanel from "./SimCreatePanel";
import SimCreateProgressBar from "./SimCreateProgressBar";
import SimCreateStepOne from "./SimCreateStepOne";
import SimCreateStepTwo from "./SimCreateStepTwo";
import SimItem from "./SimItem";
import SimTable from "./SimTable";

export default observer(function SimCreate() {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const [progress, setProgress] = useState(1);
    const lastStep = 4;

    const {simStore} = useStore();
    const {simRegistory, loadSims} = simStore;
    const [simCreatePanelOpen, setSimCreatePanelOpen] = useState(false);

    useEffect(() => {
        if(simRegistory.size < 1) simStore.loadSims();
    }, [simRegistory.size, simStore, loadSims]);

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            <div className="w-full">

              <div className="min-h-screen h-full flex flex-col after:flex-1">

                <div className="flex-1">

                {/* Progress bar */}
                <SimCreateProgressBar progress={progress} lastStep={lastStep} setProgress={setProgress} />

                <div className="px-4 py-8">
                  <div className="max-w-md mx-auto">

                    {progress === 1 && (
                      <SimCreateStepOne />
                    )}
                    {progress === 2 && (
                      <SimCreateStepTwo />
                    )}  

                    <SimCreateNav progress={progress} lastStep={lastStep} setProgress={setProgress} />

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
    )
})