import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import ProductSelector from "./ProductSelector";

export default observer(function ProductList() {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {productStore} = useStore();
    const {productRegistory , loadProducts, loading, loadCurrent, currentVariant, currentProduct} = productStore;

    useEffect(() => {
        if(productRegistory.size <= 1) loadProducts();
    }, [productRegistory.size, loadProducts]);

    useEffect(() => {
        if(!currentVariant || !currentProduct) loadCurrent();
    }, [loadCurrent, currentVariant]);


    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-slate-50">
                
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">

                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Products</h1>
                            </div>
                       
                        </div>
                    </div>
                </main>

                {loading ? (
                    <div className='mx-auto'>
                        <div className="bg-white p-5 shadow-lg rounded-sm border border-slate-200 lg:w-120">
                            <div className="text-sm text-slate-800 font-semibold mb-3">Select a Package</div>
                            <div className="text-center text-md text-slate-500 font-bold">
                            Loading...
                            </div>
                        </div>
                    </div>
                ) : (
                    <ProductSelector />
                )}

            </div>

        </div>
    )
})