import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import LogTable from "./LogTable";

export default observer(function LogList() {

    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {logStore} = useStore();
    const {logRegistory, loadLogs} = logStore;

    useEffect(() => {
        if(logRegistory.size < 1) logStore.loadLogs();
    }, [logRegistory.size, logStore, loadLogs]);

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-slate-50">
                
                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">

                        {/* Page header */}
                        <div className="sm:flex sm:justify-between sm:items-center mb-8">
                            {/* Left: Title */}
                            <div className="mb-4 sm:mb-0">
                                <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">Logs</h1>
                            </div>
                        </div>

                    </div>
                </main>

                {/* Table */}
                <LogTable />

            </div>

        </div>
    )
})