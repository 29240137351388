import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import ProductSelector from "../products/ProductSelector";
import SimCreatePanel from "./SimCreatePanel";
import SimCreateProgressBar from "./SimCreateProgressBar";
import SimItem from "./SimItem";
import SimTable from "./SimTable";

interface Props {
  progress: number;
  lastStep: number;
  setProgress: React.Dispatch<React.SetStateAction<number>>;
}

export default observer(function SimCreateNav({progress, lastStep, setProgress}: Props) {
    return (
      <div className="flex items-center justify-between">
        {progress > 1 && (
          <div
            className="btn bg-slate-400 hover:bg-slate-500 text-white mr-auto w-24"
              onClick={() => setProgress(progress - 1)}>&lt; Back</div>
        )}
        {progress < lastStep && (
          <div
          className="btn bg-indigo-500 hover:bg-indigo-600 text-white ml-auto w-32"
            onClick={() => setProgress(progress + 1)}>Next &gt;</div>
        )}
        {progress === lastStep && (
          <div
          className="btn bg-green-500 hover:bg-green-600 text-white ml-auto w-32"
            onClick={() => console.log("to do...")}>Save</div>
        )}
      </div>
    )
})