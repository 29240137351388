import { observer } from "mobx-react-lite";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import { useStore } from "../../app/stores/store";

export default observer(function SimNextPoll() {

    const {simStore, productStore} = useStore();
    const {selectedSim, lastHistory} = simStore;
    const {currentProduct} = productStore;

    if(!selectedSim) return null;

    function getMinutesBetweenDates(startDate: Date, endDate: Date) {
        var diff = new Date(startDate).getTime() - new Date(endDate).getTime();
        return Math.round(diff / 60000);
    }

    function stringUntilNextPoll() {
        if(selectedSim && currentProduct && lastHistory){
            const minsPassed = getMinutesBetweenDates(new Date(), new Date(lastHistory.createdAt + 'Z'));
            
            const windowMins = currentProduct.pollFrequency == "Daily" ? 1440 : 10080; // default to weekly
            
            const minsRemaining = windowMins - minsPassed;
            const hoursRemaining = Math.round(minsRemaining / 60);
            
            if(hoursRemaining <= 1) return "The next poll is soon"
            else if(hoursRemaining > 24) return Math.round(hoursRemaining / 24).toString() + " days until next poll";
            else return Math.round(hoursRemaining).toString() + " hours until next poll";
        }
    }            

    function percentSincePoll() {
        if(selectedSim && currentProduct && lastHistory){
            const diff = getMinutesBetweenDates(new Date(), new Date(lastHistory.createdAt + 'Z'));
            if(currentProduct.pollFrequency == "Daily"){
                return Math.round((diff / 1440) * 100);
            } else if (currentProduct.pollFrequency == "Weekly"){
                return Math.round((diff / 10080) * 100);
            }
            else {
                return 0;
            }
        }
        else return 0;
    }

    function widthPercentSincePoll() {
        const p: number = percentSincePoll();
        if(p <= 0)return "0%";
        else if (p >= 100) return "100%";
        else return Math.round(p).toString() + "%";
    }

    return (
        <div className="mt-6">
            <div className="text-sm font-semibold text-slate-800 mb-4">Device Polls</div>
            <div className="pb-4 border-b border-slate-200">
                <div className="flex justify-between text-sm mb-2">
                <div>{stringUntilNextPoll()}</div>
                <div className="italic">
                    <span className="text-slate-400">(checks performed {currentProduct?.pollFrequency})</span>
                </div>
                </div>
                <div className="relative w-full h-2 bg-slate-300">
                <div className="absolute inset-0 bg-emerald-500" aria-hidden="true" style={{ width: widthPercentSincePoll() }} />
                </div>
            </div>
        </div>
    )
})