import { RocketIcon } from '@primer/octicons-react';
import '../layout/styles.scss'
import { observer } from 'mobx-react-lite';
import React, { useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import SimDetails from '../../features/sims/SimDetails';
import SimList from '../../features/sims/SimList';
import ModalContainer from '../common/modals/ModalContainer';
import { useStore } from '../stores/store';
import LoginPage from '../../features/auth/LoginPage';
import RegisterPage from '../../features/auth/RegisterPage';
import ProductList from '../../features/products/ProductList';
import SimCreate from '../../features/sims/SimCreate';
import ContactList from '../../features/contacts/ContactList';
import ContactDetails from '../../features/contacts/ContactDetails';
import LogoutPage from '../../features/auth/LogoutPage';
import ProtectedRoute, { ProtectedRouteProps } from '../components/ProtectedRoute';
import LogList from '../../features/logs/LogList';
import LogDetails from '../../features/logs/LogDetails';
import ProfilePage from '../../features/users/ProfilePage';
import EmailConfirm from '../../features/users/EmailConfirm';

export default observer(function App() {

  const {commonStore, userStore} = useStore();
  const {user} = userStore;
  
  // Check for a token, and if we have one get the user from the API
  useEffect(() => {
    if(commonStore.token) {
      userStore.getUser().finally(() => commonStore.setAppLoaded());
    } else {
      commonStore.setAppLoaded();
    }
  }, [commonStore, userStore]);
 
  return (
      <>
          <ToastContainer position='bottom-right' hideProgressBar />
          <ModalContainer />
          <Routes>
              <Route path="/login" element={<LoginPage />} />
              <Route path="/logout" element={<LogoutPage />} />
              <Route path="/register" element={<RegisterPage />} />
              <Route path="/emailconfirm/:email/:code" element={<EmailConfirm />} />

              <Route path='/' element={<ProtectedRoute outlet={<SimList />} />} />
              <Route path='/dashboard' element={<ProtectedRoute outlet={<SimList />} />} />
              <Route path='/profile' element={<ProtectedRoute outlet={<ProfilePage />} />} />
              <Route path='/sims' element={<ProtectedRoute outlet={<SimList />} />} />
              <Route path='/sims/create' element={<ProtectedRoute outlet={<SimCreate />} />} />
              <Route path='/sims/:id' element={<ProtectedRoute outlet={<SimDetails />} />} />
              <Route path='/products' element={<ProtectedRoute outlet={<ProductList />} />} />
              <Route path='/contacts' element={<ProtectedRoute outlet={<ContactList />} />} />
              <Route path='/contacts/:id' element={<ProtectedRoute outlet={<ContactDetails />} />} />
              <Route path='/logs' element={<ProtectedRoute outlet={<LogList />} />} />
              <Route path='/logs/:id' element={<ProtectedRoute outlet={<LogDetails />} />} />
          </Routes>
      </>
  );
})