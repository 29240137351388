import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import { Link, useNavigate } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import LoginForm from "../users/LoginForm";
import RegisterForm from "../users/RegisterForm";
import { toast } from "react-toastify";

export default observer( function LogoutPage() {
    
  const {userStore} = useStore();
  const {user, logout} = userStore;
  const navigate = useNavigate();

  useEffect(() => {
    if(user){
        toast.info("Logged out");
        logout();
        navigate('/');
    } else {
      toast.error("Already logged out");
    }
  }, [user])

  return ( 
    <main className="bg-white w-full">
      <h1 className="text-center text-lg text-stone-700">Logging out...</h1>
    </main>
  )
})