import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import SimTableItem from './SimTableItem';

export default function SimTable() {

    const {simStore} = useStore();
    const {simsByCreatedAt, simCount} = simStore;

    const [selectAll, setSelectAll] = useState(false);
    const [isCheck, setIsCheck] = useState([]);
    const [list, setList] = useState([]);

//   const handleSelectAll = () => {
//   setSelectAll(!selectAll);
//   setIsCheck(list.map(li => li.id));
//   if (selectAll) {
//   setIsCheck([]);
//   }
//   };
//
//   const handleClick = e => {
//   const { id, checked } = e.target;
//   setSelectAll(false);
//   setIsCheck([...isCheck, id]);
//   if (!checked) {
//   setIsCheck(isCheck.filter(item => item !== id));
//   }
//   };
//
//   useEffect(() => {
//   selectedItems(isCheck);
//   // eslint-disable-next-line react-hooks/exhaustive-deps
//   }, [isCheck]);

  return (
    <div className="bg-white shadow-lg rounded-sm border border-slate-200 relative mx-8">
      <header className="px-5 py-4">
        <h2 className="font-semibold text-slate-800">All SIM's <span className="text-slate-400 font-medium pl-2">{simCount}</span></h2>
      </header>
      <div>

        {/* Table */}
        <div className="overflow-x-auto">
          <table className="table-auto w-full">
            {/* Table header */}
            <thead className="text-xs font-semibold uppercase text-slate-500 bg-slate-50 border-t border-b border-slate-200">
              <tr>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">SIM/Device</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Name</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Product</div>
                </th>
                <th className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
                  <div className="font-semibold text-left">Created</div>
                </th>
              </tr>
            </thead>
            {/* Table body */}
            <tbody className="text-sm divide-y divide-slate-200">
              {
                simsByCreatedAt.map(sim => {
                  return (
                    <SimTableItem sim={sim} key={sim.id} />
                  )
                })
              }
            </tbody>
          </table>

        </div>
      </div>
    </div>
  );
}