import { makeAutoObservable, runInAction } from "mobx";
import { off } from "process";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Contact, ContactCreateDTO } from "../models/contact";
import { Product, ProductVariant } from "../models/product";
import { SimCardLog } from "../models/simcardlog";


export default class LogStore {

    logRegistory = new Map<string, SimCardLog>();
    selectedLog: SimCardLog | undefined = undefined;
    loading: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get logs() {
        return Array.from(this.logRegistory.values());
    }

    get unreadCount() {
        return (this.logs.filter(s => s.readAt === null)).length;
    }

    public markAllRead = async () => {
        this.setLoading(true);
        try {
            await agent.Logs.markAllRead();
            await this.loadLogs();
            runInAction(() => {
                this.setLoading(false);
            })
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public loadLogs = async () => {
        this.setLoading(true);
        try {
            const result = await agent.Logs.list();
            result.forEach(log => {
                this.setLog(log);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public loadLog = async (id: string) => {
        this.selectedLog = undefined;
        let log = this.logRegistory.get(id);
        if(log) {
            this.selectedLog = log;
            return log;
        }
        else{
            this.setLoading(true);
            try {
                log = await agent.Logs.details(id);
                this.setLog(log);
                runInAction(() => {
                    if(log) {
                        this.selectedLog = log;
                    }
                })
                this.setLoading(false);
                return log;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    public deleteLog = async (id: string) => {
        try {
            await agent.Logs.delete(id);
            runInAction(() => {
                this.logRegistory.delete(id);
                if(this.selectedLog?.id === id) this.selectedLog = undefined;
            })
        } catch (error) {
            console.log(error);
        }
    }

    public read = async (id: string) => {
        try {
            await agent.Logs.read(id);
            runInAction(() => {
                const thisLog = this.logRegistory.get(id);
                if(thisLog) {
                    thisLog.readAt = (new Date());
                }
            })
        } catch (error) {
            console.log(error);
        }
    }

    public unread = async (id: string) => {
        try {
            await agent.Logs.unread(id);
            runInAction(() => {
                const thisLog = this.logRegistory.get(id);
                if(thisLog) {
                    thisLog.readAt = null;
                }
            })
        } catch (error) {
            console.log(error);
        }
    }
    
    private setLog = (log: SimCardLog) => {
        this.logRegistory.set(log.id, log);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

}
