import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Sim } from '../../app/models/sim';

interface Props {
    sim: Sim;
}

export default function SimTableItem({sim} : Props) {
  
  const navigate = useNavigate();

  function handleClick(destination: string) {
      navigate(destination);
  }

  return (
    <tr className={`cursor-pointer hover:bg-blue-50 ${sim.enabled ? "bg-green-50" : "bg-rose-50"}`}
      onClick={() => handleClick(`/sims/${sim.id}`)}>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src="https://www.kindpng.com/picc/m/86-860390_sim-icon-png-transparent-png.png" width="40" height="40" alt={sim.msisdn} />
          </div>
          <div className="font-medium text-slate-800">{sim.msisdn}</div>
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{sim.name}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{sim.productName}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{sim.createdAt}</div>
      </td>

    </tr>
  );
}