import { makeAutoObservable, runInAction } from "mobx";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Sim, SimCreateDto, SimUpdateDto } from "../models/sim";
import { SimHistory } from "../models/simHistory";

export default class SimStore {

    simRegistory = new Map<string, Sim>();
    selectedSim: Sim | undefined = undefined;
    loading: boolean = false;
    history: SimHistory[] = [];
    lastHistory: SimHistory | undefined = undefined;
    selectedHistory: SimHistory | undefined = undefined;
    polling: boolean = false;
    toggling: boolean = false;
    
    constructor() {
        makeAutoObservable(this);
    }

    get simsByCreatedAt() {
        return Array.from(this.simRegistory.values())
        .sort((a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime());
    }

    get historyByCreated() {
        return this.history.slice().sort((a: SimHistory, b: SimHistory) => {
            return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        })
    }

    get simCount()
    {
        return this.simRegistory.size;
    }

    public loadSims = async () => {
        this.setLoading(true);
        try {
            const result = await agent.Sims.list();
            result.forEach(sim => {
                this.setSim(sim);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public eligibleCheck = async () =>  {
        if(!this.selectedSim) return false;
        const simId = this.selectedSim.id;
        this.setPolling(true);
        try {
            const result = await agent.Sims.enroll(simId);
            runInAction(() => {
                // Force a reload of the SIM, as it should now have history
                this.simRegistory.delete(simId);
                this.loadSim(simId);    
                this.setPolling(false);
                return result.success;
            })
        } catch (error) {
            console.log(error);
            this.setPolling(false);
        }
        return false;
    } 

    public toggleSim = async (id: string) => {
        this.loadSim(id);       
        this.setToggling(true);
        if(this.selectedSim) {
            try {
                if(this.selectedSim.enabled) await agent.Sims.disable(id);
                else await agent.Sims.enable(id);
                runInAction(() => {
                    if(this.selectedSim) 
                        this.selectedSim.enabled = !this.selectedSim.enabled;
                });
                this.setToggling(false);
            } catch (error) {
                this.setToggling(false);
            }        
        }
    }

    public updateName = async (id: string, name: string) => {
        this.loadSim(id);       
        this.setToggling(true);
        if(this.selectedSim) {
            try {
                const updatedSim = await agent.Sims.update(this.selectedSim.id, {
                    "name": name
                });
                runInAction(() => {
                    if(this.selectedSim) this.selectedSim = updatedSim;
                    toast.success("Updated SIM name");
                });
                this.setToggling(false);
            } catch (error) {
                this.setToggling(false);
            }        
        }
    }

    public create = async (sim: SimCreateDto) => {
        this.setLoading(true);
        try {
            const newSim = await agent.Sims.create(sim);
            runInAction(() => {
                if(newSim) {
                    this.setSim(newSim);
                    this.loadSim(newSim.id);
                }
                this.setLoading(false);
            })
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public deleteSim = async (id: string) => {
        this.setToggling(true);
        try {
            await agent.Sims.delete(id);
            runInAction(() => {
                this.simRegistory.delete(id);
                if(this.selectedSim?.id === id) this.selectedSim = undefined;
                if(this.lastHistory) this.lastHistory = undefined;
                if(this.selectedHistory) this.selectedHistory = undefined;
            })
            this.setToggling(false);
        } catch (error) {
            console.log(error);
            this.setToggling(false);
        }
    }

    public loadSim = async (id: string) => {
        this.selectedSim = undefined;
        let sim = this.simRegistory.get(id);
        if(sim) {
            this.selectedSim = sim;
            this.setHistory(sim.history);
            return sim;
        }
        else{
            this.setLoading(true);
            try {
                sim = await agent.Sims.details(id);
                this.setSim(sim);
                runInAction(() => {
                    if(sim)
                    {
                        this.selectedSim = sim;
                        this.setHistory(sim.history);
                    }
                })
                this.setLoading(false);
                return sim;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }

    public setHistorySelected = (id: string) => {
        this.selectedHistory = this.history.find(s => s.id == id);
    }
    
    private setHistory = (history: SimHistory[]) => {
        this.history = [];
        this.lastHistory = undefined;
        if(history && history.length > 0){
            this.history = history;
            history.map((h) => {
                if(!this.lastHistory) this.lastHistory = h;
                else if(this.lastHistory.createdAt < h.createdAt) this.lastHistory = h;
            })
        }
    }

    public getLocation = async () => {
        this.setPolling(true);
        this.lastHistory = undefined;
        this.selectedHistory = undefined;
        if(this.selectedSim)
        {
            try {
                let currentHistory = await agent.Sims.poll(this.selectedSim.id);
                runInAction(() => {
                    this.lastHistory = currentHistory;
                    this.selectedHistory = currentHistory;
                    this.history.push(currentHistory);
                })
                this.setPolling(false);                

            } catch (error) {
                console.log(error);
                this.setPolling(false);
            }
        }
        else{
            toast.error("no sim selected");
        }
    }

    private setSim = (sim: Sim) => {
        this.simRegistory.set(sim.id, sim);
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

    private setPolling = (state: boolean) => {
        this.polling = state;
    }

    private setToggling = (state: boolean) => {
        this.toggling = state;
    }
}
