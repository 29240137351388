import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Contact } from '../../app/models/contact';

interface Props {
    contact: Contact;
}

export default function ContactTableItem({contact} : Props) {
  
  const navigate = useNavigate();

  function handleClick(destination: string) {
      navigate(destination);
  }

  return (
    <tr className={`hover:bg-blue-50 bg-slate-50`}
      onClick={() => handleClick(`/contacts/${contact.id}`)}>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="flex items-center">
          <div className="w-10 h-10 shrink-0 mr-2 sm:mr-3">
            <img className="rounded-full" src="https://www.kindpng.com/picc/m/86-860390_sim-icon-png-transparent-png.png" width="40" height="40" alt={contact.name} />
          </div>
          <div className="font-medium text-slate-800">{contact.name}</div>
        </div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{contact.emailAddress}</div>
      </td>

      <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
        <div className="text-left">{contact.mobileNumber}</div>
      </td>

    </tr>
  );
}