import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import ProductSelector from "../products/ProductSelector";
import SimCreatePanel from "./SimCreatePanel";
import SimCreateProgressBar from "./SimCreateProgressBar";
import SimItem from "./SimItem";
import SimTable from "./SimTable";


export default observer(function SimCreateStepOne() {

    const {simStore} = useStore();
    const {simRegistory, loadSims, eligibleCheck} = simStore;

    const [msisdn, setMsisdn] = useState("");

    return (
    <>
      <h1 className="text-3xl text-slate-800 font-bold mb-6">✨ Device SIM Support Check </h1>
      {/* Form */}

      <div className="text-center py-10 px-3 text-lg text-slate-700">
        Please enter your number below, and we will check that the device is currently supported.
      </div>

      <div className="pb-2">
        <form>
          <div className="space-y-4 mb-2">
            {/* MSISDN */}
            <div>
              <label className="block text-md font-medium mb-1" htmlFor="msisdn">MSISDN / Phone Number</label>
              <input
                id="msisdn"
                className="form-input w-full py-2 text-lg font-bold"
                placeholder="e.g. 447624121121"
                type="text"
                value={msisdn}
                onChange={(e) => setMsisdn(e.target.value)}
                />
            </div>
          </div>
        </form>
      </div>

      <div className="flex items-center justify-between mb-5">
        <div
          className="btn bg-green-500 hover:bg-green-600 text-white w-full" 
          onClick={() => eligibleCheck()}
          >Run Check</div>
      </div>

      <div className="pb-10">
        Results
      </div>


    </>
    )
})