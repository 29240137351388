import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";

export default observer(function LogDetails() {

    const {logStore} = useStore();
    const {loadLog, selectedLog, loading, read, unread} = logStore;
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const navigate = useNavigate();

    const {id} = useParams<{id: string}>();

    useEffect(() => {
        if(id) loadLog(id);
    }, [id, loadLog]);

    const handleDeleteClick = async () => {
        if(selectedLog) {
            await logStore.deleteLog(selectedLog.id);
            navigate('/logs');
        }
    }

    const handleReadClick = async () => {
        if(selectedLog){
            if(selectedLog.readAt === null) await read(selectedLog.id);
            else unread(selectedLog.id);
        }
    }

    if(loading || !selectedLog) return <LoadingComponent />

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="lg:relative lg:flex">

                        {/* Content */}
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">

                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">

                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-2xl text-slate-600 font-bold">Log Management</h1>
                                </div>

                                {/* Buttons */}
                                <div className="space-x-2">
                                    <button
                                        className={`btn bg-rose-400 text-white hover:text-black hover:bg-red-300`}
                                        onClick={() => handleDeleteClick()}
                                    >Delete Log</button>
                                    <button
                                        className={`btn ${selectedLog.readAt === null ? "bg-sky-400" : "bg-yellow-400"} text-white hover:text-black hover:bg-slate-300`}
                                        onClick={() => handleReadClick()}
                                    >{selectedLog.readAt === null ? "Mark Read" : "Mark UnRead"}</button>
                                </div>

                            </div>

                            {/* Contacts */}
                            <div className="space-y-2">
                                <div className="text-md text-slate-700">Created: {selectedLog.createdAt?.toString()}</div>
                                <div className="text-md text-slate-700">Read: {selectedLog.readAt?.toString()}</div>
                                <div className="text-md text-slate-700">Name: {selectedLog.name}</div>
                                <div className="text-md text-slate-700">Description: {selectedLog.description}</div>
                                <div className="text-md text-slate-700">Sim: <Link to={`/sims/${selectedLog.simCardId}`}>Click Here</Link> {selectedLog.simCardId}</div>
                            </div>

                        </div>

                    </div>
                </main>

            </div>
        </div>
    )
})