import React, { useState, useEffect } from 'react';
import { useStore } from '../../app/stores/store';
import { observer } from "mobx-react-lite";
import ProductSelectorItem from './ProductSelectorItem';
import ProductSelectorBilling from './ProductSelectorBilling';

declare global {
  interface Window {
    Paddle: any;
  }
}

export default observer(function ProductSelector(){

  const {productStore} = useStore();
  const {loading, productByPrice, selectedProduct, quantity, selectedVariant} = productStore;

  const Paddle = window.Paddle;
  const openCheckout  = (productId: number) => { 
      Paddle.Checkout.open({
          product: productId,
          quantity: quantity
//          email: 'tom@sharkey.im'
        });
  }

  if(loading) return null;
  // <button className="border" onClick={openCheckout}>Subscribe Now!</button>{' '}

  const updateProduct = (productId: string) => {
    productStore.loadProduct(productId);
  }

  return (
    <div className='mx-auto'>
      <div className="bg-white p-5 shadow-lg rounded-sm border border-slate-200 lg:w-120">
        <div className="text-sm text-slate-800 font-semibold mb-3">Select a Package</div>

        {/* Pricing toggle */}
        <ProductSelectorBilling />

        <ul className="space-y-2 lg:space-y-2 lg:space-x-0 lg:flex-col mb-4">
          {productByPrice.map((product) => (
            <ProductSelectorItem product={product} key={product.id}/>
          ))}
        </ul>
        <div className="mb-4">
          {selectedProduct && selectedVariant ? (
            <div
              className="btn w-full bg-indigo-500 hover:bg-indigo-600 text-white"
              onClick={() => openCheckout(selectedVariant.paddleProductId)}
              >Buy Now - {selectedVariant.currencySymbol}{selectedVariant.monthlyPrice.toFixed(2)}</div>
          ) : (
            <div className="btn w-full bg-indigo-200 hover:bg-indigo-400 text-white">Select a Product</div>
          )}
        </div>
        <div className="text-xs text-slate-500 italic text-center">Payments are managed by Paddle, you must also agree to our <a className="underline hover:no-underline" href="#0">Terms</a>.</div>
      </div>
    </div>
  );
})