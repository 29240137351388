import { LatLngExpression } from "leaflet";
import { observer } from "mobx-react-lite";
import React from "react";
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet'
import ReactTimeago from "react-timeago";
import { SimHistory } from "../../app/models/simHistory";
import { useStore } from "../../app/stores/store";

interface Props {
    history: SimHistory;
}

export default observer(function SimHistoryItem({history} : Props) {

    const {simStore} = useStore();
    const {setHistorySelected, selectedHistory} = simStore;

    const thisIsSelected = selectedHistory && selectedHistory.id == history.id;

    const position: LatLngExpression = [51.505, -0.09]

    return (
        <label className="relative block cursor-pointer text-left w-full">
            <input type="radio" name="radio-buttons" className="peer sr-only" defaultChecked />
            <div className={`p-4 rounded border  hover:bg-purple-200 hover:bg-purple-50 shadow-sm duration-150 ease-in-out ${thisIsSelected ? ("border-purple-500 border-2") : ("border-slate-200")}`}
                onClick={() => setHistorySelected(history.id)} >
                <div className="grid grid-cols-12 items-center gap-x-2">
                    {/* Card */}
                    <div className="col-span-1 flex items-center space-x-4 lg:sidebar-expanded:col-span-1 xl:sidebar-expanded:col-span-1">
                        <svg className="shrink-0" width="32" height="24" xmlns="http://www.w3.org/2000/svg">
                            <defs>
                            <linearGradient x1="1.829%" y1="100%" x2="100%" y2="2.925%" id="c1-a">
                                <stop stopColor="#475569" offset="0%" />
                                <stop stopColor="#1E293B" offset="100%" />
                                <stop stopColor="#9FA1FF" offset="100%" />
                            </linearGradient>
                            </defs>
                            <g fill="none" fillRule="evenodd">
                            <rect fill="url(#c1-a)" width="32" height="24" rx="3" />
                            <ellipse fill="#E61C24" fillRule="nonzero" cx="12.522" cy="12" rx="5.565" ry="5.647" />
                            <ellipse fill="#F99F1B" fillRule="nonzero" cx="19.432" cy="12" rx="5.565" ry="5.647" />
                            <path
                                d="M15.977 7.578A5.667 5.667 0 0 0 13.867 12c0 1.724.777 3.353 2.11 4.422A5.667 5.667 0 0 0 18.087 12a5.667 5.667 0 0 0-2.11-4.422Z"
                                fill="#F26622"
                                fillRule="nonzero"
                            />
                            </g>
                        </svg>
                    </div>

                    <div className="col-span-1 sm:col-span-2 text-left sm:text-center">
                        <div className="text-sm font-medium text-slate-800 truncate grid">
                            <span className="text-slate-400 text-sm">Location</span>
                            <span className="bg-slate-700 text-white rounded-lg mx-6 font-mono">{history.locationSignature}</span>
                        </div>
                    </div>

                    <div className="col-span-1 sm:col-span-2 text-left sm:text-center">
                        <div className="text-sm font-medium text-slate-800 truncate grid">
                            <span className="text-slate-400 text-sm">SIM</span>
                            <span className="bg-slate-700 text-white rounded-lg mx-6 font-mono">{history.deviceSignature}</span>
                        </div>
                    </div>

                    {/* Created At */}
                    <div className="col-span-2 sm:col-span-3 text-left sm:text-center">
                        <div className="text-sm font-medium text-slate-800 truncate">
                            <ReactTimeago date={history.createdAt} /><br /> <span className="text-xs text-slate-400">{history.createdAt}</span>
                        </div>
                    </div>

                    {/* Roaming Indication */}
                    {!history.roaming ? (
                        <div className="col-span-2 sm:col-span-2 text-center">
                            <div className="text-xs inline-flex font-medium bg-green-100 text-green-600 rounded-full text-center px-2.5 py-1">
                                Not Roaming
                            </div>
                        </div>
                    ) : (
                        <div className="col-span-2 sm:col-span-2 text-center">
                            <div className="text-xs inline-flex font-medium bg-orange-100 text-orange-600 rounded-full text-center px-2.5 py-1">
                                Roaming
                            </div>
                        </div>
                    )} 

                    {/* Ported Indication */}
                    {!history.ported ? (
                        <div className="col-span-2 sm:col-span-2 text-center">
                            <div className="text-xs inline-flex font-medium bg-purple-100 text-purple-600 rounded-full text-center px-2.5 py-1">
                            Not Ported
                            </div>
                        </div>
                    ) : (
                        <div className="col-span-2 sm:col-span-2 text-center">
                            <div className="text-xs inline-flex font-medium bg-orange-100 text-orange-600 rounded-full text-center px-2.5 py-1">
                                Ported
                            </div>
                        </div>
                    )}            

                </div>
                {thisIsSelected && (
                    <div className="h-64 p-2 shadow-sm border border-slate-100 mt-4">
                        <MapContainer center={position} zoom={4} scrollWheelZoom={false}>
                            <TileLayer
                                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                            />
                        </MapContainer>
                    </div>
                )}
            </div>
        </label>
    )
})