import { observer } from "mobx-react-lite";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import ReactTimeago from "react-timeago";
import { toast } from "react-toastify";
import LoadingComponent from "../../app/layout/LoadingComponent";
import { useStore } from "../../app/stores/store";
import Header from "../navigation/Header";
import Sidebar from "../navigation/Sidebar";
import SimEnrollment from "./SimEnrollment";
import SimHistoryItem from "./SimHistoryItem";
import SimHistorySidebar from "./SimHistorySidebar";
import PostItem from "./SimItem";

export default observer(function SimDetails() {

    const {simStore} = useStore();
    const {historyByCreated, loadSim, selectedSim, loading, getLocation, polling} = simStore;
    const [sidebarOpen, setSidebarOpen] = useState(false);

    const {id} = useParams<{id: string}>();

    useEffect(() => {
        if(id) loadSim(id);
    }, [id, loadSim]);

    if(loading || !selectedSim) return <LoadingComponent />

    return (
        <div className="flex h-screen overflow-hidden">

            {/* Sidebar */}
            <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

            {/* Content area */}
            <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden bg-white">

                {/*  Site header */}
                <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

                <main>
                    <div className="lg:relative lg:flex">

                        {/* Content */}
                        <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto ">

                            {/* Page header */}
                            <div className="sm:flex sm:justify-between sm:items-center mb-5">

                                {/* Left: Title */}
                                <div className="mb-4 sm:mb-0">
                                    <h1 className="text-2xl md:text-2xl text-slate-600 font-bold">SIM Management</h1>
                                </div>

                                {/* Add card button */}
                                {selectedSim.enabled ? (
                                    <button
                                        className={`btn hover:bg-slate-400 text-white hover:text-black ${polling ? 'bg-indigo-500' : 'bg-green-500'}`}
                                        onClick={() => getLocation()}
                                        disabled={polling}
                                    >{polling ? ("Checking...") : ("Poll Now")}</button>
                                ) : (
                                    <button
                                        className={`btn hover:bg-rose-400 text-white hover:text-black bg-rose-200`}
                                        onClick={() => toast.error("SIM Must be Enabled to Poll")}
                                        disabled={polling}
                                    >Poll Now</button>
                                )}

                            </div>

                            {/* SIM Cards */}
                            <div className="space-y-2">
                                
                                {!selectedSim.enrolled ? (
                                    <SimEnrollment simCard={selectedSim} />
                                ) : (
                                    <>
                                        <h2 className="text-xl md:text-1xl text-slate-800 font-bold">Recent SIM History</h2>
                                        {historyByCreated.map((history) => (
                                            <SimHistoryItem history={history}  key={history.id} />
                                        ))}
                                    </>
                                )}
                            
                            </div>

                        </div>

                        <SimHistorySidebar />

                    </div>
                </main>

            </div>
        </div>
    )
})