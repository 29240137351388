import { makeAutoObservable, runInAction } from "mobx";
import { off } from "process";
import { toast } from "react-toastify";
import agent from "../api/agent";
import { Product, ProductVariant } from "../models/product";


export default class ProductStore {

    productRegistory = new Map<string, Product>();
    variantRegistory = new Map<string, ProductVariant>();
    selectedProduct: Product | undefined = undefined;
    selectedVariant: ProductVariant | undefined = undefined;
    loading: boolean = false;
    yearlySelected: boolean = false;
    quantity: number = 1;

    currentProduct: Product | undefined = undefined;
    currentVariant: ProductVariant | undefined = undefined;
    currentConsumed: number = 0;
    currentQuantity: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get productByPrice() {
        return Array.from(this.productRegistory.values());
    }

    public setYearlySelected = (value: boolean) => {

        this.yearlySelected = value;

        if(this.selectedProduct && this.selectedVariant)
        {
            const frequency = this.yearlySelected ? "Yearly" : "Monthly";
            const newVariant = this.selectedProduct.variants.find(s => s.billingFrequency === frequency);
            if(newVariant) {
                this.setProductVariant(this.selectedProduct.id, newVariant?.id);
            }
            else {
                this.selectedProduct = undefined;
                this.selectedVariant = undefined;
            } 
        }
    }

    public loadProducts = async () => {
        this.setLoading(true);
        try {
            const result = await agent.Products.list();
            result.forEach(product => {
                this.setProduct(product);
            });
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }  
    }

    public loadCurrent = async () => {
        this.setLoading(true);
        try {
            const currentProduct = await agent.Products.current();
            runInAction(() => {
                if(currentProduct) {
                    if(!this.productRegistory.has(currentProduct.product.id)) {
                        this.setProduct(currentProduct.product);
                    }
                    this.currentVariant = currentProduct.product.variants.find(s => s.id === currentProduct.variantId);
                    this.currentProduct = currentProduct.product;
                    this.currentConsumed = currentProduct.consumed;
                    this.currentQuantity = currentProduct.quantity;
                }
            })
            this.setLoading(false);
        } catch (error) {
            console.log(error);
            this.setLoading(false);
        }
    }

    public setProductVariant = async (productId: string, variantId: string) => {

        await this.loadProduct(productId);
        runInAction(() => {
            this.selectedVariant = this.selectedProduct?.variants.find(s => s.id === variantId);

            if(this.selectedProduct)
            {
                if(this.selectedProduct.supportsQuantity === false) this.quantity = 1;
                else{
                    if(this.selectedProduct.minQuantity > this.quantity) this.quantity = this.selectedProduct.minQuantity
                    if(this.selectedProduct.maxQuantity < this.quantity) this.quantity = this.selectedProduct.maxQuantity;
                }
            }
        })
    }

    public setQuantity = (qty: number) => {

        if(this.selectedProduct?.minQuantity && qty > this.selectedProduct?.minQuantity
            && this.selectedProduct.maxQuantity && qty < this.selectedProduct.maxQuantity)
            {
                this.quantity = qty;
            }
    }

    public loadProduct = async (id: string) => {
        this.selectedProduct = undefined;
        let product = this.productRegistory.get(id);
        if(product) {
            this.selectedProduct = product;
            return product;
        }
        else{
            this.setLoading(true);
            try {
                product = await agent.Products.details(id);
                this.setProduct(product);
                runInAction(() => {
                    if(product)
                    {
                        this.selectedProduct = product;
                    }
                })
                this.setLoading(false);
                return product;
            } catch (error) {
                console.log(error);
                this.setLoading(false);
            }
        }
    }
    
    private setProduct = (product: Product) => {
        if(product.variants)
        {
            this.productRegistory.set(product.id, product);
            product.variants.map((variant) => {
                this.variantRegistory.set(variant.id, variant);  
            })
        }
    }

    private setLoading = (state: boolean) => {
        this.loading = state;
    }

}
